<template>
    <div class="f2-no-padding">
        <ion-progress-bar
            v-if="!src && !isInlineHtml"
            type="indeterminate"
        />
        <iframe
            ref="iframe"
            :src="src"
            :style="definition.resolvedStyles"
            :class="definition.resolvedClasses"
        />
    </div>
</template>

<script lang="ts">
import {Capacitor} from '@capacitor/core';
import {defineComponent, ref, watch, onBeforeUnmount, nextTick} from 'vue';
import {IonProgressBar} from '@ionic/vue';
import {useI18n} from 'vue-i18n';
import {useStore} from 'vuex';
import {key} from '../store';
import resolve, {resolveAsync} from '@/framework2-sdk/dependencyResolver';
import {validators} from '@/framework2-sdk/tools/validator';
import dataSourceRegistry from '@/dataSourceRegistry';
import {dataSource} from '@/functionality/dataSource';
import {emit} from '@/functionality/events';
import {TagsToResolve} from '../helpers';
import config from '../config';
import * as api from '../api';
import CacheHandler, {inlineHTML} from '../cacheHandler';

export default defineComponent({
    components: {
        IonProgressBar,
    },
    props: {
        definition: {
            required: true,
            type: Object,
        },
        data: {
            required: false,
            type: Object,
        },
    },
    setup(props) {
        const {locale} = useI18n();
        const store = useStore(key);
        const iframe = ref();
        const src = ref();
        const imageSizeKeys = Object.keys(config.imageSizes);
        const tagsToResolve = new TagsToResolve({
            storage: store.state.user.storage,
            self: Array.isArray(props.data) ? props.data[0] : props.data,
        });
        const isInlineHtml = ref(false);
        let isLoadFinishedDone = false;
        const listeners: {[key: string]: Function[]} = {
            callF2Api: [({config, id, isFile = false}: { config: any, id: number, isFile: boolean }) => {
                if (typeof config === 'string') {
                    config = {
                        url: config,
                    };
                }
                if (isFile || config.url.match(new RegExp(`^/api/files/${validators.regExpIsUIDString}(/(${imageSizeKeys.join('|')}))?$`))) {
                    Object.assign(config, {
                        responseType: 'arraybuffer',
                        transformResponse: (data: any, headers: any) => {
                            const base64String = btoa(
                                new Uint8Array(data)
                                    .reduce((d, byte) => d + String.fromCharCode(byte), ''),
                            );
                            return `data:${headers['content-type'].toLowerCase()};base64,${base64String}`;
                        },
                    });
                }

                api.get()?.callApi(config)
                    .then(
                        (data: any) => iframe.value?.contentWindow.postMessage({eventName: 'f2ApiResult', data: {id, data}}, isInlineHtml.value ? '*' : store.state.user.credentials.baseURL),
                        (error: any) => iframe.value?.contentWindow.postMessage({eventName: 'f2ApiResult', data: {id, error}}, isInlineHtml.value ? '*' : store.state.user.credentials.baseURL),
                    );
            }],
            loadFinished: [() => {
                if (!isLoadFinishedDone && Array.isArray(props.definition.events)) {
                    isLoadFinishedDone = true;
                    props.definition.events
                        .filter((e) => e.type.startsWith('f2'))
                        .forEach((e) => {
                            if (!(e.type in listeners)) {
                                listeners[e.type] = [];
                            }
                            listeners[e.type].push((event: any) => {
                                emit(e.type, {events: [e]}, [{eventData: event.data, record: props.data}]);
                            });
                        });

                    const eventDefinition = props.definition.events.find((e: any) => e.type === 'created');
                    if (!eventDefinition || !eventDefinition.data) {
                        return;
                    }
                    resolveAsync(eventDefinition.data, tagsToResolve, dataSourceRegistry, locale.value)
                        .then((data) => iframe.value?.contentWindow.postMessage({eventName: 'created', data: data}, isInlineHtml.value ? '*' : store.state.user.credentials.baseURL));
                }
            }],
        };
        (async function() {
            if (
                props.definition.data &&
                props.definition.data.source &&
                props.definition.data.source.uid ||
                props.definition.src
            ) {
                if (props.definition.src) {
                    if (props.definition.data) {
                        const dataSourceInfo = dataSource(props.definition);
                        watch(dataSourceInfo.isDataReady, async () => {
                            if (dataSourceInfo.data.value?.length) {
                                tagsToResolve.self = dataSourceInfo.data.value[0];
                            }
                            src.value = await resolveAsync(props.definition.src, tagsToResolve);
                        });
                    } else {
                        src.value = resolve(props.definition.src, tagsToResolve);
                    }
                } else {
                    const cacheHandler = api.get()?.cacheHandler as CacheHandler | undefined;
                    if (cacheHandler?.isActive || Capacitor.getPlatform() === 'ios') {
                        let html;
                        if (cacheHandler?.isActive) {
                            html = await cacheHandler.getStaticDataDefinitionContent(props.definition.data.source.uid);
                        } else {
                            html = await api.get()?.readData(props.definition.data.source.uid) as string;
                            html = await inlineHTML(html);
                        }
                        const doc = iframe.value.contentWindow.document;
                        doc.open();
                        doc.write(html);
                        doc.close();
                        isInlineHtml.value = true;
                        await nextTick();
                        setTimeout(() => {
                            listeners.loadFinished[0]();
                        }, Capacitor.getPlatform() === 'ios' ? 150 : 0); // bad workaround for safari
                    } else {
                        src.value = `${store.state.user.credentials.baseURL}/app-key/${store.state.user?.application.key}/api/data/${props.definition.data.source.uid}`;
                    }
                }
            }
        })();
        function listener(e: MessageEvent) {
            if (
                (isInlineHtml.value ||e.origin === store.state.user.credentials.baseURL) &&
                Array.isArray(listeners[e.data.eventName])
            ) {
                listeners[e.data.eventName]
                    .forEach((callback) => callback(e.data.data));
            }
        }
        window.addEventListener('message', listener);
        onBeforeUnmount(() => {
            window.removeEventListener('message', listener);
        });
        return {
            src,
            iframe,
            isInlineHtml,
        };
    },
});
</script>

<style scoped>
iframe {
    width: 100%;
    height: calc(100vh - 117px);
    border: none;
}
</style>
