<template>
    <ion-datetime
        v-if="definition.isInlinePicker"
        ref="datetime"
        :value="pickerValue"
        :presentation="presentation"
        v-bind="additionalInputAttributes"
        @ionChange="dateChanged"
    />
    <div
        v-else
        style="width: 100%"
    >
        <ion-label
            v-if="label"
            position="stacked"
        >
            {{ label }}
        </ion-label>
        <ion-button
            style="float: right"
            @click="isModalOpen = !isModalOpen"
        >
            <ion-icon
                slot="icon-only"
                :icon="calendarOutline"
            />
        </ion-button>
        <ion-label
            style="padding: 10px 0 5px;"
            @click="isModalOpen = !isModalOpen"
        >
            {{ formattedValue || '&nbsp;' }}
        </ion-label>
        <ion-modal
            :is-open="isModalOpen"
            :class="`${presentation}-picker`"
            @didDismiss="isModalOpen = false"
        >
            <ion-content>
                <ion-datetime
                    ref="datetime"
                    :value="pickerValue"
                    :presentation="presentation"
                    v-bind="additionalInputAttributes"
                    @ionChange="dateChanged"
                >
                    <ion-buttons slot="buttons">
                        <ion-button
                            v-if="!isRequired"
                            @click="datetime.$el.reset(); datetime.$el.confirm();"
                        >
                            {{ $t('action.clear') }}
                        </ion-button>
                        <ion-button @click="datetime.$el.confirm()">
                            {{ $t('action.ok') }}
                        </ion-button>
                    </ion-buttons>
                </ion-datetime>
            </ion-content>
        </ion-modal>
    </div>
</template>
<script lang="ts">
import {computed, defineComponent, ref} from 'vue';
import {
    IonModal,
    IonButtons,
    IonButton,
    IonContent,
    IonDatetime,
    IonIcon,
    IonLabel,
} from '@ionic/vue';
import {formField} from '../functionality/form';
import {useI18n} from 'vue-i18n';
import {
    calendarOutline,
} from 'ionicons/icons';
import moment from 'moment';

export default defineComponent({
    components: {
        IonModal,
        IonContent,
        IonDatetime,
        IonButtons,
        IonButton,
        IonIcon,
        IonLabel,
    },
    props: {
        definition: {
            required: true,
            type: Object as () => any,
        },
        model: {
            required: false,
            type: Object,
        },
        isRequired: {
            required: false,
            type: Boolean,
            default: false,
        },
        additionalInputAttributes: {
            required: false,
            type: Object,
        },
        label: {
            required: false,
            type: String,
        },
    },
    setup(props) {
        const toUTCString = function(date: string | Date | undefined) {
            const d: any = date instanceof Date ? date : (date && new Date(date));
            const userTimezoneOffset = d?.getTimezoneOffset() * 60000;
            return date && new Date(d.getTime() - userTimezoneOffset).toISOString();
        };

        const {t} = useI18n();
        const {formModel, fieldName, value} = formField(props);
        const datetime = ref();
        const isModalOpen = ref(false);
        const pickerValue = ref(toUTCString(value));
        let presentation = 'date-time';
        let format = 'lll';
        if (props.definition.type === 'date') {
            presentation = 'date';
            format = 'L';
        } else if (props.definition.type === 'time') {
            presentation = 'time';
            format = 'LT';
        }

        const dateChanged = function(event: any) {
            const date = event.detail.value && new Date(event.detail.value).toISOString();
            updateDate(date);
        };

        const updateDate = function(date: string) {
            formModel[fieldName] = date;
            pickerValue.value = toUTCString(date);
            isModalOpen.value = false;
        };

        const formattedValue = computed(() => {
            return pickerValue.value ? moment(formModel[fieldName]).format(format) : null;
        });

        return {
            formattedValue,
            calendarOutline,
            t,
            pickerValue,
            dateChanged,
            updateDate,
            isModalOpen,
            props,
            formModel,
            fieldName,
            value,
            presentation,
            datetime,
        };
    },
});
</script>
<style scoped lang="scss">
	ion-item > ion-button {
		--padding-start: 0;
		--padding-end: 0;
		--border-radius: 13px;
		font-size: 11px;
		height: 26px;
		width: 26px;
	}

    ion-modal {
        --width: 290px;
        --height: 396px;
        --border-radius: 8px;

        &.date-time-picker {
            --height: 457px;
        }
        &.time-picker {
            --height: 252px;
        }
    }

    ion-modal ion-datetime {
		--ion-color-base: var(--ion-color-secondary) !important;
    }
</style>
