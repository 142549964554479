import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-31a6b1de"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  style: {"width":"100%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_datetime = _resolveComponent("ion-datetime")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_modal = _resolveComponent("ion-modal")!

  return (_ctx.definition.isInlinePicker)
    ? (_openBlock(), _createBlock(_component_ion_datetime, _mergeProps({
        key: 0,
        ref: "datetime",
        value: _ctx.pickerValue,
        presentation: _ctx.presentation
      }, _ctx.additionalInputAttributes, { onIonChange: _ctx.dateChanged }), null, 16, ["value", "presentation", "onIonChange"]))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.label)
          ? (_openBlock(), _createBlock(_component_ion_label, {
              key: 0,
              position: "stacked"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.label), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_ion_button, {
          style: {"float":"right"},
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isModalOpen = !_ctx.isModalOpen))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_icon, {
              slot: "icon-only",
              icon: _ctx.calendarOutline
            }, null, 8, ["icon"])
          ]),
          _: 1
        }),
        _createVNode(_component_ion_label, {
          style: {"padding":"10px 0 5px"},
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isModalOpen = !_ctx.isModalOpen))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.formattedValue || ' '), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_ion_modal, {
          "is-open": _ctx.isModalOpen,
          class: _normalizeClass(`${_ctx.presentation}-picker`),
          onDidDismiss: _cache[4] || (_cache[4] = ($event: any) => (_ctx.isModalOpen = false))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_content, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_datetime, _mergeProps({
                  ref: "datetime",
                  value: _ctx.pickerValue,
                  presentation: _ctx.presentation
                }, _ctx.additionalInputAttributes, { onIonChange: _ctx.dateChanged }), {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_buttons, { slot: "buttons" }, {
                      default: _withCtx(() => [
                        (!_ctx.isRequired)
                          ? (_openBlock(), _createBlock(_component_ion_button, {
                              key: 0,
                              onClick: _cache[2] || (_cache[2] = ($event: any) => {_ctx.datetime.$el.reset(); _ctx.datetime.$el.confirm();})
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('action.clear')), 1)
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        _createVNode(_component_ion_button, {
                          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.datetime.$el.confirm()))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('action.ok')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 16, ["value", "presentation", "onIonChange"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["is-open", "class"])
      ]))
}